import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class OriginService {
  public origin: string = '';

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.origin = window.location.hostname;
    // tslint:disable-next-line:max-line-length
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        window.location.hostname
      )
    ) {
      // check if origin is an IP address
      this.origin = 'ckoi.com';
    }
  }
}
