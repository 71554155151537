import { from as observableFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';

import { Environment } from './../../../env/env';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class BaseApiService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private httpClient: HttpClient,
    protected transferState: TransferState
  ) {}

  public getEndpoint(useNewApi: boolean): string {
    if (!useNewApi) {
      return Environment.apiPublicUrl;
    } else {
      return Environment.apiV2PublicUrl;
    }
  }

  get http(): HttpClient {
    return this.httpClient;
  }

  public get(path: string, options: any = {}, useApiV2: boolean = false): Observable<any> {
    if (isPlatformServer(this.platformId)) {
      return new Observable();
    }
    return this.http.get(this.getEndpoint(useApiV2) + path, { params: options });
  }

  public post(
    path: string,
    body: any,
    options: any = {},
    useApiV2: boolean = false
  ): Observable<any> {
    return this.http.post(this.getEndpoint(useApiV2) + path, body, { params: options });
  }

  public getWithTransferState(
    path: string,
    options: any = {},
    useApiV2: boolean = false
  ): Observable<any> {
    return this.getData(
      this.getEndpoint(useApiV2),
      path,
      { params: options },
      (url: string, params: any = {}) => {
        return this.http.get(url, params);
      }
    );
  }

  private getData(
    endpoint: string,
    url: string,
    options: any,
    callback: (url: string, options?: any) => Observable<any>
  ) {
    const key = makeStateKey<any>(url + JSON.stringify(options));

    try {
      return this.getTransferStateData(key);
    } catch (e) {
      return callback(`${endpoint}${url}`, options).pipe(
        tap((data) => {
          this.transferState.set(key, data);
        })
      );
    }
  }

  private getTransferStateData(key: string) {
    const stateKey = makeStateKey<any>(key);
    const data = this.transferState.get(stateKey, null);

    if (!data) {
      throw new Error();
    }

    return observableFrom(Promise.resolve(data));
  }
}
