import { Injectable } from '@angular/core';

@Injectable()
export class SlugService {
  constructor() {}

  public slugify(text: string) {
    // const slug = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const slug = this.removeAccents(text);
    return slug
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  private removeAccents(str: any) {
    const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    const strLen = str.length;
    let i;
    for (i = 0; i < strLen; i++) {
      const index = accents.indexOf(str[i]);
      if (index !== -1) {
        str[i] = accentsOut[index];
      }
    }
    return str.join('');
  }
}
