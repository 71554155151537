import { Injectable } from '@angular/core';
import { StationService } from './station.service';
import { SlugService } from './slug.service';
import { Router } from '@angular/router';

@Injectable()
export class LinkService {
  private mobileAppSubdomain: string;
  private websiteSubdomain: string;
  private language: string;
  private domain: string;

  constructor(private stationService: StationService, private slugService: SlugService, private router: Router) {
    this.stationService.waitUntilReady(() => {
      if (this.stationService.station.configurations) {
        this.mobileAppSubdomain = this.stationService.station.configurations.mobileapp_subdomain;
        this.websiteSubdomain = this.stationService.station.configurations.website_subdomain;
      }
      this.language = this.stationService.station.language;
      this.domain = this.stationService.station.domain;
    });
  }

  public websiteDomain(): string {
    if (this.websiteSubdomain) {
      return [this.websiteSubdomain, this.domain].join('.');
    } else {
      return this.domain;
    }
  }

  public getWebsiteCurrentUrl(route: string): string {
    return ['https://', this.websiteDomain(), route].join('');
  }

  public mobileappDomain(): string {
    if (this.mobileAppSubdomain) {
      return [this.mobileAppSubdomain, this.domain].join('.');
    } else {
      return this.domain;
    }
  }

  public getMobileAppCurrentUrl(route: string): string {
    return ['https://', this.mobileappDomain(), route].join('');
  }

  public getWebsiteAudioExcerptUrl(item: any) {
    return (
      'https://' +
      this.websiteDomain() +
      '/audio/' +
      item.id +
      '/' +
      this.slugService.slugify(item.title)
    );
  }

  public getWebsiteEpisodeUrl(item: any, podcastId: any) {
    const fragment = this.language === 'fr' ? 'balados/' : 'podcasts/';

    return (
      'https://' +
      this.websiteDomain() +
      '/' +
      fragment +
      podcastId +
      '/episode/' +
      item.id +
      '/' +
      item.slug.split('/')[0]
    );
  }

  public getContentUrl(item: any, audio?: boolean) {
    if (!item) {
      return '';
    }

    const urlParts: any[] = [];
    urlParts.push(audio ? '/audio' : (this.language === 'fr' ? '/nouvelles' : '/news'));
    urlParts.push(item.id);
    urlParts.push(item.slug);

    return this.router.createUrlTree(urlParts).toString();
  }
}
