import { BaseApiService } from '../api/base-api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CategoryService {
  public categories: any[];
  private cbs: any[] = [];

  constructor(private api: BaseApiService) {
    this.api.get('/category', {}, true).subscribe((res: any) => {
      this.categories = res.category;
      this.cbs.forEach((cb) => cb());
      this.cbs = [];
    });
  }

  public waitUntilReady(cb: any) {
    if (this.categories) {
      cb();
    } else {
      this.cbs.push(cb);
    }
  }

  public getCategoryDetailFromCategory(category: any) {
    const info: any = {parentCategory: null, childCategory: null};
    if(category && category.id) {
      // Is parent category
      if(this.categories.map((cat: any) => cat.id).includes(category.id)) {
        info.parentCategory = category;
        return info;
      }

      // Is child category
      const parentCategory: any = this.categories.filter((cat: any) => {
        return cat.childCategories.filter((scat: any) => {
          return scat.id === category.id
        }).length > 0;
      });
      if(parentCategory.length > 0) {
        info.parentCategory = parentCategory[0];
        info.childCategory = parentCategory[0].childCategories.filter((scat: any) => scat.id === category.id)[0];
        return info;
      }
    }
    return info;
  }

  public getCategoryBySlug(slug: string) {
      // Is parent category
      let category = this.categories.filter((cat: any) => { return cat.slugFr === slug || cat.slugEn === slug; });
      if(category.length > 0) {
        return category[0];
      }
      // Child category
      category = this.categories.filter((cat: any) => {
        return cat.childCategories.filter((scat: any) => {
          return scat.slugFr === slug || scat.slugEn === slug
        }).length > 0;
      });
      if(category.length > 0) {
        return category[0].childCategories.filter((scat: any) => scat.slugFr === slug || scat.slugEn === slug)[0];
      }
  }
}
