import { Component } from '@angular/core';
import { StationService } from '../../core/services/station.service';

@Component({
  selector: 'cmi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  public isOpen = false;
  public isFrenchStation: boolean;

  constructor(private stationService: StationService) {
    this.stationService.waitUntilReady(() => {
      if (this.stationService.station.language === 'fr') {
        this.isFrenchStation = true;
      } else {
        this.isFrenchStation = false;
      }
    });
  }

  public toggleNav(): void {
    this.isOpen = !this.isOpen;
  }

  public closeNav(): void {
    this.isOpen = false;
  }
}
