<div [ngClass]="classes">
  <ng-container *ngIf="displayBar">
    <cmi-navbar></cmi-navbar>
  </ng-container>

  <router-outlet></router-outlet>

  <div class="ad ad--video" id="video-ad">
    <div class="ad__message">{{ 'ADS.AUDIO_RESUME_AFTER_AD' | translate }}</div>
    <div class="ad__container" id="video-ad-container">
      <video class="video-ad-element" id="video-ad-element" playsinline></video>
    </div>
  </div>
  <div class="ad-overlay"></div>
</div>
