import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { StationService } from './station.service';
import { LinkService } from './link.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SocialShareService {
  public webNavigator: any = null;
  private stationName: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private translateService: TranslateService,
    private stationService: StationService,
    private linkService: LinkService
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.webNavigator = navigator;
    this.stationService.waitUntilReady(() => {
      this.stationName = this.stationService.station.name;
    });
  }

  public canUseShare() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.webNavigator !== null && this.webNavigator.share !== undefined) {
      return true;
    }
  }

  public nativeShare({ title, text, url }: { title: string; text?: string; url?: string }) {
    return new Promise(async (resolve, reject) => {
      if (this.canUseShare()) {
        if ((text === undefined || text === null) && (url === undefined || url === null)) {
          console.warn(`text and url both can't be empty, at least provide either text or url`);
        } else {
          try {
            const isShared = await this.webNavigator.share({
              title,
              text,
              url,
            });
            resolve({
              shared: true,
            });
          } catch (error) {
            reject({
              shared: false,
              error,
            });
          }
        }
      } else {
        reject({
          shared: false,
          error: `This service/api is not supported in your Browser`,
        });
      }
    });
  }

  public facebookShareUrl(contentUrl: string, redirectUrl: string) {
    const params = new URLSearchParams();
    params.append('app_id', '504647650075947');
    params.append('link', contentUrl);
    params.append('redirect_uri', redirectUrl);
    params.append('display', 'popup');
    return 'https://www.facebook.com/dialog/feed?' + params.toString();
  }

  public twitterShareUrl(contentUrl: string, text: string) {
    const params = new URLSearchParams();
    params.append('url', contentUrl);
    params.append('text', text);

    return 'https://twitter.com/intent/tweet?' + params.toString();
  }

  public emailShareUrl(subject: string, url: string) {
    const message = this.completeEmailBody(url);
    return 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + message;
  }

  private completeEmailBody(url: string) {
    return this.translateService.instant('SHARE.MESSAGE', {
      stationName: this.stationName,
      shareUrl: encodeURIComponent(url),
      stationUrl: encodeURIComponent(this.linkService.getWebsiteCurrentUrl('')),
    });
  }
}
