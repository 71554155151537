import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

import { Station } from './../../core/models/station.interface';
import { StationService } from '../../core/services/station.service';
import { VideoAdsService } from './../../core/services/video-ads.service';
import { Environment } from '../../../env/env';

@Component({
  selector: 'cmi-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public station: Station;
  public stationStyle: string;
  public displayBar: boolean = !Environment.production;

  public classes: string[];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private stationService: StationService,
    private videoAdsService: VideoAdsService
  ) {}

  public isStationStyle(style: string): boolean {
    return this.stationStyle === style;
  }

  public onDeactivate(): void {
    document.body.scrollTop = 0;
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.stationService.waitUntilReady(() => {
        if (this.stationService.station.id && !isNaN(this.stationService.station.id)) {
          if (
            this.stationService.station &&
            this.stationService.station.style &&
            this.stationService.station.type
          ) {
            this.station = this.stationService.station;
            this.classes = [`station--${this.station.style}`, `layout--${this.station.type}`];
          }
        }

        // Lazy load theme stylesheet
        const link = document.createElement('link');
        link.href = this.stationService.station.domain + '.css';
        link.rel = 'stylesheet';
        link.type = 'text/css';

        const head = document.getElementsByTagName('head')[0];

        head.appendChild(link);

        this.videoAdsService.setupAds();
      });
    }
  }
}
