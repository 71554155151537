import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Injectable()
export class MetaService {
  public origin: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private titleService: Title,
    private meta: Meta
  ) {}

  public setContentMetaTag(content: any) {
    this.setContentTitle(content);

    this.meta.addTags([
      {
        name: 'og:author',
        content: this.getContentAuthor(content),
      },
      {
        name: 'og:description',
        content: content.description || '',
      },
    ]);
  }

  public setContentTitle(content: any) {
    this.titleService.setTitle(content.name);

    this.meta.addTag({
      name: 'og:title',
      content: content.name,
    });
  }

  public clearMetadata() {
    this.titleService.setTitle('');
    this.meta.removeTag("name='og:title'");
    this.meta.removeTag("name='og:author'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='keyword'");
  }

  private getContentAuthor(content: any): string {
    if (content.customAuthor) {
      return content.customAuthor;
    } else if (
      content.author &&
      content.author[0] &&
      content.author[0].firstName &&
      content.author[0].lastName
    ) {
      return `${content.author[0].firstName} ${content.author[0].lastName}`;
    } else {
      return '';
    }
  }
}
