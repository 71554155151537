import { Injectable, Inject, PLATFORM_ID, Injector, Optional } from '@angular/core';

import { OriginService } from './origin.service';
import { BaseApiService } from './../api/base-api.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

@Injectable()
export class StationService {
  public station: any;
  public stationsIds: any = {
    CKOI: 1,
    THEBEAT: 2,
    RYTHMEMONTREAL: 3,
    '985FM': 4,
    MFM: 5,
    FM93: 6,
    FM1047: 7,
    FM1069: 8,
    FM1077: 9,
    RYTHMEESTRIE: 10,
    RYTHMESAGUENAY: 11,
    RYTHMEMAURICIE: 12,
    CIME: 13,
    RADIOCIRCULATION: 14,
    FURY: 15,
    PHOENIX: 16,
    IMPACT: 17,
    ALOUETTES: 18,
    CANADIENS: 19,
    REPECHAGE: 20,
    PRESSE: 21,
    PLANETE1045: 22,
    PLANETE995: 23,
    PLANETE935: 24,
    PLANETE1003: 25,
    KYK: 26,
    CAPITALEROCK: 27,
    WOWABITIBI: 28,
    WOWLACHUTE: 29,
    ROUGEETNOIR: 30,
    RYTHMEQUEBEC: 33,
  };

  private cbs: any[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(REQUEST) private request: any,
    private api: BaseApiService,
    private translate: TranslateService
  ) {
    let domain: string = '';
    if (isPlatformBrowser(this.platformId)) {
      if (window) {
        domain = this.getMainOrigin(window.location.hostname);
      }
    } else {
      domain = this.getMainOrigin(this.request.hostname);
    }
    this.loadStationData(domain);
  }

  public isStation(name: string) {
    if (this.station && this.station.id === this.stationsIds[name]) {
      return true;
    }
    return false;
  }

  public isStations(names: string[]) {
    for (const name of names) {
      if (this.station && this.station.id === this.stationsIds[name]) {
        return true;
      }
    }
    return false;
  }

  public isStationType(type: string) {
    if (this.station && this.station.type === type) {
      return true;
    }
    return false;
  }

  public isStationStyle(style: string) {
    if (this.station && this.station.style === style) {
      return true;
    }
    return false;
  }

  public isStationViewExtended() {
    if (this.station && this.station.extendedView === true) {
      return true;
    }
    return false;
  }

  public waitUntilReady(cb: any) {
    if (this.station) {
      cb();
    } else {
      this.cbs.push(cb);
    }
  }

  private stationIsReady() {
    this.cbs.forEach((cb) => cb());
    this.cbs = [];
  }

  private getMainOrigin(origin: string) {
    // originService.origin
    let parts = origin.split(':');
    parts = parts[0].split('.');

    if (parts.length < 2) {
      return origin;
    }

    const mainOrigin = parts[parts.length - 2] + '.' + parts[parts.length - 1];

    if (
      mainOrigin !== 'rythmefm.com' &&
      mainOrigin !== 'cime.fm' &&
      mainOrigin !== 'planeteradio.ca'
    ) {
      return mainOrigin;
    }

    // rythmefm
    return parts[parts.length - 3] + '.' + parts[parts.length - 2] + '.' + parts[parts.length - 1];
  }

  private loadStationData(domain: any) {
    this.api.getWithTransferState('/stations/domain/' + domain).subscribe(
      (res) => {
      this.setStation(res);
      },
      (err: any) => {
        console.log('Server');
        console.log(this.request.hostname);
        console.log(this.request.path);
      });
  }

  private setStation(stateData: any) {
    if (stateData && stateData.station) {
      try {
        this.station = stateData.station;
      } catch (e) {
        this.station = null;
      }
    }

    this.translate.setDefaultLang(this.station.language);
    this.translate.use(this.station.language);
    this.stationIsReady();

    return;
  }
}
