import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StationService } from '../services/station.service';

@Injectable()
export class EnGuard  {
  constructor(private stationService: StationService, private router: Router) {}

  public canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.stationService.waitUntilReady(() => {
        if (this.stationService.station.language === 'en') {
          resolve(true);
          return;
        }

        this.router.navigate(['/404']);
        resolve(false);
      });
    });
  }
}
