<div class="navbar">
  <button (click)="toggleNav()"><span></span></button>
</div>

<nav class="nav" [class.is-open]="isOpen">
  <ul (click)="closeNav()">
    <ng-container *ngIf="isFrenchStation; else englishMenu">
      <li><a [routerLink]="'/nouvelles'">Articles (list page)</a></li>
      <li><a [routerLink]="'/balados'">Balados (list page)</a></li>
      <li><a [routerLink]="'/photos'">Albums Photos (list page)</a></li>
      <li><a [routerLink]="'/emissions'">Emissions (list page)</a></li>
      <li><a [routerLink]="'/emissions/horaire'">Horaire</a></li>
      <li><a [routerLink]="'/animateurs'">Animateurs (list page)</a></li>
      <li><a [routerLink]="'/concours'">Concours (list page)</a></li>
    </ng-container>

    <ng-template #englishMenu>
      <li><a [routerLink]="'/news'">News (list page)</a></li>
      <li><a [routerLink]="'/podcasts'">Podcast (list page)</a></li>
      <li><a [routerLink]="'/photos'">Photos Albums (list page)</a></li>
      <li><a [routerLink]="'/shows'">Shows (list page)</a></li>
      <li><a [routerLink]="'/shows/schedule'">Schedule</a></li>
      <li><a [routerLink]="'/hosts'">Hosts (list page)</a></li>
      <li><a [routerLink]="'/contests'">Contests (list page)</a></li>
    </ng-template>
  </ul>
</nav>
