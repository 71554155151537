import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppClientModule } from './app/app.client.module';
import { fetchConfig, Environment } from './env/env';
import { hmrBootstrap } from './app/core/hmr';

if (Environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppClientModule);

function bootstrapClient() {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      bootstrap().catch((err: any) => console.log(err));
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        bootstrap().catch((err: any) => console.log(err));
      });
    }
  }
}

let disableDynamicConfig = true;
if (localStorage && localStorage.getItem('cgc_dynamic_config')) {
  disableDynamicConfig = false;
}

if (Environment.env === 'prod' && disableDynamicConfig) {
  bootstrapClient();
} else {
  fetchConfig()
    .then((responseConfig: any) => {
      if (responseConfig && responseConfig.data) {
        Object.keys(Environment).forEach((key) => {
          Environment[key] = responseConfig.data[key] || Environment[key];
        });
        console.log('Client config updated with fetched "app.json".');
      }
      bootstrapClient();
    })
    .catch(() => {
      console.log('Error load dynamic config - load app with default config');
      bootstrapClient();
    });
}
