import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  BrowserModule,
  /* BrowserTransferStateModule, */
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  TransferState,
} from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { MainComponent } from './layout/main/main.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import * as Hammer from 'hammerjs';

import { Environment } from './../env/env';
import { CommonModule } from '@angular/common';
import { StationService } from './core/services/station.service';
import { BaseApiService } from './core/api/base-api.service';
import { MetaService } from './core/services/meta.service';
import { OriginService } from './core/services/origin.service';
import { SlugService } from './core/services/slug.service';
import { TagManagerService } from './core/services/tag-manager.service';
import { TranslateBrowserLoaderService } from './core/services/translate-browser-loader.service';
import { FrGuard } from './core/guard/fr-guard.service';
import { EnGuard } from './core/guard/en-guard.service';
import { LinkService } from './core/services/link.service';
import { SocialShareService } from './core/services/social-share.service';
import { CategoryService } from './core/services/category.service';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { VideoAdsService } from './core/services/video-ads.service';

export function HttpLoaderFactory(
  http: HttpClient,
  transferState: TransferState
): TranslateBrowserLoaderService {
  return new TranslateBrowserLoaderService(http, '/assets/i18n/', '.json', transferState);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, MainComponent, NotFoundComponent, NavbarComponent],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'mobileapp' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    /* BrowserTransferStateModule, */
    CommonModule,
    TransferHttpCacheModule,
    HttpClientModule,
  ],
  providers: [
    BaseApiService,
    MetaService,
    SlugService,
    StationService,
    OriginService,
    TagManagerService,
    FrGuard,
    EnGuard,
    LinkService,
    SocialShareService,
    CategoryService,
    VideoAdsService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcOhtEUAAAAAIeSnsf4OwF8Cw2qPOXkVqXJxY5A',
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr', // TODO fix language for english websites
    },
  ],
  exports: [TranslateModule, AppComponent],
})
export class AppClientModule {
  constructor() {}
}
